import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { navigate } from "gatsby-link"
import styled, {keyframes} from "styled-components"
import get from "lodash/get"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import { useMedia } from "react-use"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"

const formSubmittedMessage = "Kiitos! Lomakkeen tiedot on nyt lähetetty."

const PageWrapper = styled.div`
    overflow: hidden;
    max-width: 49em;
    margin: 1em auto 0 auto;
    @media (min-width: 1000px) {
        margin: 2em auto;
    }
`

const PageTitle = styled.div`
    font-size: ${({length}) => (length > 15) ? "5vw" : "10vw"};
    font-family: Raleway, sans-serif;
    font-weight: 100;
    margin-bottom: 0em;
    margin-left: -0.07em;
    line-height: 0.9em;
    @media (min-width: 1000px) {
        font-size: ${({length}) => (length > 15) ? "3em" : "6em"};
        margin-bottom: ${({hasHeaderImage}) => hasHeaderImage ? "0.4em" : "0"};
    }
`

const Hero = styled.div`
    position: relative;
    overflow: hidden;
    min-height: ${({showMap}) => showMap ? "26vw" : "30vw"};
    @media (min-width: 1000px) {
        min-height: ${({showMap}) => showMap ? "10em" : "16em"};
    }
`

const HeroBackground = styled.div`
    background: transparent url(${({src}) => src}) no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    filter: brightness(0.4) contrast(90%) grayscale(100%);
    height: 40vw;
    @media (min-width: 1000px) {
        height: 26vw;
    }
`

const HeroContentWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const HeroContent = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 49em;
    margin: 0 auto;
    position: relative;
    z-index: 11;
    padding: 1em;
    box-sizing: border-box;
    @media (min-width: 1000px) {
        padding: 0;
    }
`

const PageContent = styled.div`
    padding: 1em;
    @media (min-width: 1000px) {
        padding: 0;
    }
    p {
        color: #909090;
        line-height: 1.8em;
        margin-bottom: 3em;
        white-space: pre-wrap;
    }
    @media (min-width: 1000px) {
        font-size: 1.1em;
    }
`

const Form = styled.form`
    @media (min-width: 1000px) {
        font-size: 1.2em;
    }
`

const FormRow = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    align-items: flex-start;
    @media (min-width: 1000px) {
        flex-direction: row;
        justify-content: flex-end;
    }
`

const Label = styled.label`
    color: #909090;
    padding-top: 0.5em;
    @media (min-width: 1000px) {
        width: 12em;
    }
`

const Input = styled.input`
    background: #181818;
    border: 1px solid #303030;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    padding: 0.5em;
    color: #f0f0f0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border-color: #fb578a;
    }
    @media (min-width: 1000px) {
        flex: 1;
        width: auto;
    }
`

const TextArea = styled.textarea`
    background: #181818;
    border: 1px solid #303030;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    padding: 0.5em;
    color: #f0f0f0;
    width: 100%;
    display: block;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border-color: #fb578a;
    }
    @media (min-width: 1000px) {
        flex: 1;
        width: auto;
    }
`

const Select = styled.select`
    width: 100%;
    font-size: 1em;
    background: transparent;
    color: #f0f0f0;
    padding: 0.5em;
    border: 1px solid #303030;
    font-family: inherit;
    font-size: inherit;
    font-weight: 300;
    box-sizing: border-box;
    &:focus {
        outline: none;
        border-color: #fb578a;
    }
    @media (min-width: 1000px) {
        flex: 1;
        width: auto;
    }
`

const buttonFlash = keyframes`
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
`

const Submit = styled.input`
    display: flex;
    border: 1px solid #fb578a;
    color: #fb578a;
    padding: 1em;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 0.9em;
    font-weight: 400;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    justify-self: flex-end;
    align-self: flex-end;
    margin-top: 1em;
    &:hover {
        background: transparent;
        animation: ${buttonFlash} 250ms ease-out;
        border-color: #fff;
        cursor: pointer;
        color: #fff;
    }
`

const MapWrapper = styled.div`
    height: 10em;
    margin-top: 5em;
    filter: grayscale(100%);
    position: relative;
    @media (min-width: 1000px) {
        height: 20em;
    }
`

const CourseFormTitle = styled.h3`

`

const EmbeddedContentImage = styled("img")`
  width: 100%;
  display: block;
`

const ContentVideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
`

const ContentVideo = styled("video")`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
`

const contentOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const target = node.data.target
          if (!target) {
            return null
          }
          if (target && target.fixed) {
            // the asset is an image
            return <EmbeddedContentImage src={target.fixed.src} />
          } else if (target) {
            // not an image, probably video then ¯\_(ツ)_/¯
            return (
              <ContentVideoWrapper>
                <ContentVideo controls>
                  <source src={target.localFile.publicURL} />
                </ContentVideo>
              </ContentVideoWrapper>
            )
          }
          return null
        },
      },
}

function encode(data) {
    const formData = new FormData()

    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }

    return formData
}

const CourseForm = () => {
    return <Form name="Course Form" method="POST" data-netlify="true" enctype="multipart/form-data">
        <input type="hidden" name="form-name" value="Course Form" />
        <CourseFormTitle>Ilmoittaudu kurssille</CourseFormTitle>
        <FormRow>
            <Label>Kurssi / kurssit</Label>
            <Input name="kurssi" />
        </FormRow>
        <FormRow>
            <Label>Nimi</Label>
            <Input name="nimi" />
        </FormRow>
        <FormRow>
            <Label>Syntymäaika</Label>
            <Input name="syntymäaika" />
        </FormRow>
        <FormRow>
            <Label>Pituus</Label>
            <Input name="pituus" />
        </FormRow>
        <FormRow>
            <Label>Katuosoite</Label>
            <Input name="katuosoite" />
        </FormRow>
        <FormRow>
            <Label>Postinumero ja -toimipaikka</Label>
            <Input name="postinumero ja toimipaikka" />
        </FormRow>
        <FormRow>
            <Label>Puhelin</Label>
            <Input name="puhelin" />
        </FormRow>
        <FormRow>
            <Label>Sähköposti</Label>
            <Input name="email" />
        </FormRow>
        <FormRow>
            <Label>Maksajan osoite, sähköpostiosoite ja puhelinnumero</Label>
            <TextArea name="maksaja" rows={3} />
        </FormRow>
        <FormRow>
            <Submit type="submit" value="Lähetä" />
        </FormRow>
    </Form>
}

const ContactForm = () => {
    return <Form name="Contact Form" method="POST" data-netlify="true" enctype="multipart/form-data">
        <input type="hidden" name="form-name" value="Contact Form" />
        <FormRow>
            <Label>Olen kiinnostunut</Label>
            <Select>
                <option>kurssit</option>
                <option>mallikansio</option>
                <option>KAUNEUSKILPAILUT</option>
                <option>MALLIN BOOKKAUS KUVAUKSIIN</option>
                <option>muotinäytöksen järjestäminen</option>
                <option>asiakastapahtuma</option>
                <option>juontajat</option>
                <option>näyttelijät</option>
                <option>muu</option>
            </Select>
        </FormRow>
        <FormRow>
            <Label>Nimi</Label>
            <Input name="nimi" />
        </FormRow>
        <FormRow>
            <Label>Puhelinnumero</Label>
            <Input name="puhelin" />
        </FormRow>
        <FormRow>
            <Label>Viesti</Label>
            <TextArea name="viesti" />
        </FormRow>
        <FormRow>
            <Submit type="submit" value="Lähetä" />
        </FormRow>
    </Form>
}

const ModelForm = () => {
    const [state, setState] = React.useState({})

    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleAttachment = (e) => {
      setState({ ...state, [e.target.name]: e.target.files[0] })
    }

    const handleSubmit = (e) => {
      e.preventDefault()
      const form = e.target
      fetch('/', {
        method: 'POST',
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => {
            alert(formSubmittedMessage)
            window.location.reload();
        })
        .catch((error) => alert(error))
    }

    return <Form name="Model Form" method="POST" data-netlify="true" enctype="multipart/form-data" onSubmit={handleSubmit}>
        <input type="hidden" name="form-name" value="Model Form" />
        <FormRow>
            <Label>Nimi</Label>
            <Input name="nimi" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Syntymäaika</Label>
            <Input name="syntymäaika" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Pituus</Label>
            <Input name="pituus" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Katuosoite</Label>
            <Input name="katuosoite" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Postinumero ja kaupunki</Label>
            <Input name="postinumero ja kaupunki" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Puhelin</Label>
            <Input name="puhelin" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Sähköposti</Label>
            <Input name="email" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Label>Kuva 1</Label>
            <Input name="kuva1" type="file" onChange={handleAttachment} />
        </FormRow>
        <FormRow>
            <Label>Kuva 2</Label>
            <Input name="kuva2" type="file" onChange={handleAttachment} />
        </FormRow>
        <FormRow>
            <Label>Lisätiedot</Label>
            <TextArea name="lisätiedot" onChange={handleChange} />
        </FormRow>
        <FormRow>
            <Submit type="submit" value="Lähetä" />
        </FormRow>
    </Form>
}

const Page = ({ data }) => {
  const page = data.contentfulPage
  const title = get(page, "title")
  const content = get(page, "content")
  const headerImage = get(page, "headerImage")
  const showMap = get(page, "showMap")
  const isWide = useMedia('(min-width: 1000px)')
  return (
    <>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Layout page={page}>
            {showMap && 
                <MapWrapper>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7615.543652625143!2d23.757408!3d61.4982164!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcad4b58e624f919d!2sStudio%20Face!5e0!3m2!1sen!2sfi!4v1644467028913!5m2!1sen!2sfi"
                        width="100%"
                        height="100%"
                        style={{border: "0"}}
                        allowfullscreen=""
                        loading="lazy">
                    </iframe>
                </MapWrapper>
            }
            <Hero showMap={showMap}>
                {headerImage && <HeroBackground src={headerImage.fixed.src} />}
                <HeroContentWrapper>
                    <HeroContent>
                        <PageTitle hasHeaderImage={!!headerImage} length={title.length}>{title}</PageTitle>
                    </HeroContent>
                </HeroContentWrapper>
            </Hero>
            <PageWrapper>
                <PageContent>
                    {content && renderRichText(content, contentOptions)}

                    {page.slug === "yhteystiedot" && <ContactForm />}
                    {page.slug === "hae-malliksi" && <ModelForm />}
                    {["kurssit", "mallikurssi", "lapsimallikurssi", "missikurssi"].includes(page.slug) && <CourseForm />}
                </PageContent>
            </PageWrapper>
        </Layout>
    </>
  )
}

export const query = graphql`
  query ContentfulPage($slug: String) {
    contentfulPage(slug: { eq: $slug }) {
        title
        slug
        content {
          raw
          references {
            __typename
            ... on ContentfulAsset {
              contentful_id
              fixed(width: 1440) {
                src
              }
              publicURL
            }
          }
        }
        headerImage {
            fixed(width: 2000, quality: 80) {
                src
                width
                height
            }
        }
        showMap
    }
  }
`

export default Page
